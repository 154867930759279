export const slides = [{
  name: '自動車車検・修理は協和自動車へ',
  src: '/images/top-01.png',
  sp: '/images/top-01-sp.png'
}, {
  name: '縁JOY',
  src: '/images/top-02.jpg',
  sp: '/images/top-02-sp.jpg'
}, {
  name: '愛車に優しい3星整備',
  src: '/images/top-03.jpg',
  sp: '/images/top-03-sp.jpg'
}];
